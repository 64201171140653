import React from "react"
import Guide from "../../../components/guide2020"

const IndexPage = ({data}) => (
  <Guide
    Year={"2020"}
    Section={"reise"}
    ProductName={"Reiseforsikring"}
    Entity={"reise"}
    Attributes={40}
    Stars5={{
      need: "For deg som reiser på lengre og kostbare reiser, og vil være aller best sikret",
      description: 'De aller beste reiseforsikringene - 5 defaqto stjerner',
      terms: [
        {t: "Medisinske utgifter",c: "Må ha ubegrenset dekning"},
        {t: "Invaliditet voksen",c: "Må være 600 000 kr eller mer"},
        {t: "Invaliditet barn",c: "Må være 700 000 kr eller mer"},
        {t: "Reisegods",c: "Total dekningssum for bagasje på 400 000 kr eller mer"},
        {t: "Dekningssum enkel enhet",c: "Dekket for 40 000 kr eller mer"},
        {t: "Dekningssum verdigjenstand",c: "Dekket for 40 000 kr eller mer"},
        {t: "Avbestilling dekningssum",c: "Dekkes for 400 000 kr eller mer"},
        {t: "Innhenting av reiserute per person",c: "Dekket for 30 000 kr eller mer"},
        {t: "Personlig ansvar",c: "Dekker for 15 000 000 eller mer"},
        {t: "Egenandelskrav for leiebilskade",c: "Dekket for 10 000 kr eller mer"},
      ]
    }}
    Stars4={{
      need: "For deg som reiser mye, men kanskje ikke trenger de aller høyeste dekningssummene i markedet",
      description: 'Veldig gode reiseforsikringer - 4 defaqto stjerner',
      terms: [
        {t: "Medisinske utgifter",c: "Må ha ubegrenset dekning"},
        {t: "Invaliditet voksen",c: "Må være 300 000 kr eller mer"},
        {t: "Invaliditet barn",c: "Må være 500 000 kr eller mer"},
        {t: "Reisegods",c: "Total dekningssum for bagasje på 100 000 kr eller mer"},
        {t: "Dekningssum enkel enhet",c: "Dekket for 30 000 kr eller mer"},
        {t: "Dekningssum verdigjenstand",c: "Dekket for 30 000 kr eller mer"},
        {t: "Avbestilling dekningssum",c: "Dekkes for 200 000 kr eller mer"},
        {t: "Innhenting av reiserute per person",c: "Dekket for 20 000 kr eller mer"},
        {t: "Personlig ansvar",c: "Dekker for 10 000 000 eller mer"},
        {t: "Egenandelskrav for leiebilskade",c: "Dekket for 10 000 kr eller mer"},
      ]
    }}
    Stars3={{
      need: "For deg som trenger en god reiseforsikring, uten de høyeste dekningssummene",
      description: 'Gode reiseforsikringer - 3 defaqto stjerner',
      terms: [
      ]
    }}
  />
)

export default IndexPage